import api from "!../../../node_modules/_style-loader@2.0.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/_css-loader@5.2.7@css-loader/dist/cjs.js!../../../node_modules/_postcss-loader@4.3.0@postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./style.css";

var options = {"injectType":"singletonStyleTag","attributes":{"data-cke":true}};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};